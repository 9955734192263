import axios from 'axios';
const ROOT = 'https://8kers.com/wp-json';
const ROOT_REST = `${ROOT}/wp/v2`;
const ROOT_CUSTOM = `${ROOT}/wp/v2/api`;
const urlCategories = 'game_categories';
const urlGames = 'games';
const urlPlays = 'plays';

export const getGameCategories = async () => {
  try {
    const response = await fetch(`${ROOT_REST}/${urlCategories}`);
    return response.json() || [];
  } catch (error) {
    return []
  }
}

export const getGames = async (id: number, per_page = 50) => {
  try {
    let params = `&per_page=${per_page}&`;

    if(id) {
      params += `&game_categories=${id}`;
    }

    const response = await fetch(`${ROOT_REST}/${urlGames}?_embed${params}`);
    return response.json() || [];
  } catch (error) {
    return []
  }
}

export const getGamesNew = async () => {
  try {
    const response = await fetch(`${ROOT_CUSTOM}/games`);
    return response.json() || [];

  } catch (error) {
    return []
  }
}

export const getPopularGames = async () => {
  try {
    const response = await fetch(`${ROOT_CUSTOM}/games`);
    return (response.json() || []);
  } catch (error) {
    return []
  }
}

export const getRandom3Games = async (per_page = 3) => {
  try {
    const randomURL = per_page === 4 ? 'random_4_games' : 'random_games';
    const response = await fetch(`${ROOT_CUSTOM}/${randomURL}`);
    return response.json() || [];
  } catch (error) {
    return []
  }
}

export const getGame = async (id: number) => {
  try {
    const response = await axios(`${ROOT_REST}/${urlGames}/${id}?_embed`);
    return response.data || [];
  } catch (error) {
    return []
  }
}

export const getPlay = async (id: number) => {
  try {
    const response = await axios(`${ROOT_REST}/${urlPlays}/${id}?_embed`);
    return response.data || [];
  } catch (error) {
    return []
  }
}
