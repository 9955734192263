import Play from "../components/Play";
import Ads from "../components/Ads";

const PagePlay = () => {
  return <>
    <Ads />
    <Play />
  </>
}

export default PagePlay;