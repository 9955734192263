import './pagination.css';
import React, { useState } from 'react';

const Pagination = ({ totalItems, itemsPerPage, onPageChange }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    onPageChange(newPage);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <li onClick={() => handlePageChange(i)} key={i} className={i === currentPage ? 'active' : ''}>
          <a rel="nofollow">{i}</a>
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <div className="pagination">
      <ul>
        <li onClick={() => {
          if(currentPage > 1) {
            handlePageChange(currentPage - 1)
          }
        }}>
          <svg className='arrow-left' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
            <path d="M19 5l-7 7 7 7" />
          </svg>
        </li>
        {renderPageNumbers()}
        <li onClick={() => {
          if(currentPage < totalPages) {
            handlePageChange(currentPage + 1)
          }
        }}>
          <svg className='arrow-right' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
            <path d="M5 5l7 7-7 7" />
          </svg>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
