import { Routes, Route } from "react-router-dom";
import './App.css';
import PageCategories from './pages/categories';
import PageGames from './pages/category';
import PageSingleGame from './pages/singleGame';
import PageSinglePlay from './pages/singlePlay';
import Page404 from './pages/404';
import PageHome from "./pages/Home";

const logoText = process.env.REACT_APP_LOGO_TEXT;
const adsHeader = `<script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"></script>
<script>
  window.googletag = window.googletag || {cmd: []};
  googletag.cmd.push(function() {
    googletag.defineSlot('/22668877269/2kers.com_2410/2kers.com_PC_banner_2901', [[750, 200], [728, 90], [970, 90], [468, 60]], 'div-gpt-ad-1706502141848-0').addService(googletag.pubads());
    googletag.pubads().enableSingleRequest();
    googletag.enableServices();
  });
</script>`;

const App = () => {
  return (
    <div className="container-fluid minHeight100vh">
      <div className="header">
        <div className="container">
          <a className="text-large" style={{ color: '#fff' }} href="/" rel="noopener noreferrer">{logoText}</a>
        </div>
      </div>
      <div dangerouslySetInnerHTML={{ __html: adsHeader }} />
      <div className="container">
        <Routes>
          <Route path='*' element={<Page404 />} />
          <Route path="/" element={<PageHome />}/>
          <Route path="/categories" element={<PageCategories />}/>
          <Route path="/category/:id" element={<PageGames />}/>
          <Route path="/games" element={<PageGames />}/>
          <Route path="/game/:id" element={<PageSingleGame />}/>
          <Route path="/play/:id" element={<PageSinglePlay />}/>
        </Routes>
      </div>
      <div className="footer">
        <div className="container text-center">© 2024 {logoText}</div>
      </div>
    </div>
  );
};

export default App;
