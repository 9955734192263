import React, { useEffect, useState } from "react";
import { getPopularGames } from '../../apis/index.ts';
import Pagination from '../Pagination';
import Card from "../Card/index.js";
import Image from "../Image/index.js";
import PlaceHolder from "../PlaceHolder/index.js";
import Star from '../Star';

const PopularGames = ({ isMobile = false, noPaging, maxItem = 48, per_page = 12 }) => {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  useEffect(() => {
    getPopularGames().then(data => {
      if(Array.isArray(data)) {
       const dataSorted = data.sort((a, b) => b.viewed - a.viewed);
       setGames(dataSorted)
      }
      
      setLoading(false);
    })
  }, []);

  const getShow = (page) => {
    if(noPaging) return games.slice(0, maxItem);

    return games.slice((page - 1) * per_page, (page - 1) * per_page + per_page);
  }

  const arr = new Array(per_page).fill(null);
  if(loading) return <div className="popular-games grid grid-4 m-grid-1">
    {arr.map((_, i) => {
      return <PlaceHolder key={`placeholder_games_${i}`} width='100%' height="100px" />
    })}
  </div>;

  return (
    <>
      <div className="popular-games grid grid-4 m-grid-1">
          {getShow(page).map((game) => {
            return <Card key={`popular_game_${game.id}`}>
              <a key={`popular_${game.id}`} href={`/game/${game.id}`}>
                <div className="flex-h">
                  <Image
                    loading="lazy"
                    alt={game.title}
                    src={game.featured_image}
                    style={{ width: 100, height: 100, borderRadius: 10 }}
                  />
                  <div className="flex-v" style={{ padding: 10 }}>
                    <span className="text-primary max-2-line" dangerouslySetInnerHTML={{ __html: game.title }} />
                    <Star style={{ marginLeft: -5}} />
                    <div className="game-played">{game.viewed} Played</div>
                  </div>
                </div>
              </a>
            </Card>
          })}
      </div>
      {!noPaging && <div className="flex-h flex-center" style={{ marginTop: 30 }} justify="center">
        <Pagination onPageChange={(page) => setPage(page)}  totalItems={games.length} itemsPerPage={per_page} />
      </div>}
    </>
  )
}

export default PopularGames;