
import React, { useEffect, useState } from "react";
import { getGameCategories } from '../../apis/index.ts';
import Card from '../Card';
import Image from "../Image/index.js";
import Star from '../Star';
import PlaceHolder from '../../components/PlaceHolder';

const GameCategories = () => {
  const [gameCategories, setGameCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getGameCategories().then(data => {
      if(Array.isArray(data)) {
        setGameCategories(data)
        setLoading(false)
      }
    })
  }, []);

  const arr = new Array(6).fill(null);
  if(loading) return <div className="game-categories grid grid-6 m-grid-2">
    {arr.map((_, i) => {
      return <PlaceHolder key={`placeholder_game_category_${i}`} width='100%' height="60px" />
    })}
  </div>;

  return (
    <>
      <div className="game-categories grid grid-6 m-grid-2">
          {gameCategories.map(category => {
            return <a key={category.id} href={`/category/${category.id}`}>
              <Card className='flex-h flex-y-center' >
                  <Image
                    alt={category.name}
                    src={category?.acf?.image}
                    style={{ width: 80, height: 80, borderRadius: 10 }}
                  />
                  <div className="flex-v" style={{ marginLeft: 10, maxHeight: 80  }}>
                    <span style={{ fontSize: 11 }} className="text-primary max-1-line">{category.name}</span>
                    <Star style={{ marginLeft: -5 }} />
                    <span style={{ fontSize: 11 }} className="game-played max-1-line">{category.count + 50} games</span>
                  </div>
                </Card>
              </a>
            })}
      </div>
    </>
  )
}

export default GameCategories;