import React, { useEffect, useState } from "react";
import { getRandom3Games } from '../../apis/index.ts';
import Card from "../Card/index.js";
import Image from "../Image/index.js";
import PlaceHolder from "../PlaceHolder/index.js";

const Random3Games = ({ layout = 'horizontal', per_page = 3 }) => {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getRandom3Games(per_page).then(data => {
      if(Array.isArray(data)) setGames(data)
      setLoading(false);
    })
  }, []);

  const classLayout = layout === 'vertical' ? 'random3game grid w200 mr-20' : 'random3game grid grid-3 m-grid-3';

  if(loading) return <div className={classLayout}>
    <PlaceHolder width='100%' height="200px" />
    <PlaceHolder width='100%' height="200px" />
    <PlaceHolder width='100%' height="200px" />
  </div>;

  return (
    <>
      <div className={classLayout}>
          {games.map((game) => {
            return <a key={game.id} style={{ display: 'inline-block', height: '100%' }} href={`/game/${game.id}`}>
              <Card>
                <Image
                  loading="lazy"
                  decoding="async"
                  alt={game.title}
                  src={game?.featured_image}
                />
              </Card>
              </a>
          })}
      </div>
    </>
  )
}

export default Random3Games;