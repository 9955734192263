import GameCategories from "../components/GameCategories";
import GamesNew from "../components/GamesNew";
import PopularGames from "../components/PopularGames";
import Ads from "../components/Ads";

const Home = () => {
  const isMobile = window.innerWidth < 768 ? true : false;

  return (
    <>
      <GameCategories />

      <Ads />

      <h2 className="section-title">New Games</h2>
      <GamesNew layout={{ desktop: 8, mobile: 3}} isMobile={isMobile} hideText={true} noPaging={isMobile ? false: false} maxItem={40} per_page={isMobile ? 12: 24} />

      <h2 className="section-title">Popular Games</h2>
      <PopularGames isMobile={isMobile} hideText={true} noPaging={isMobile ? false: false} maxItem={20} per_page={isMobile ? 8: 16} />
    </>
  )
}

export default Home;