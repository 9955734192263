import { useParams } from "react-router-dom";
import GamesNew from "../components/GamesNew";
import Ads from "../components/Ads";

const PageGames = () => {
  const { id } = useParams();
  return <>
    <Ads />
    <GamesNew categoryId={id} hideText={true} />
  </>
}

export default PageGames;