const Star = ({ ...attrs }) => {
  return <div {...attrs} className="flex-h">
    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24">
      <path fill="#FFD700" d="M12 2l2.471 5.499h5.528l-4 4.086 0.942 6.197-4.942-2.721-4.942 2.721 0.942-6.197-4-4.086h5.528z"/>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24">
      <path fill="#FFD700" d="M12 2l2.471 5.499h5.528l-4 4.086 0.942 6.197-4.942-2.721-4.942 2.721 0.942-6.197-4-4.086h5.528z"/>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24">
      <path fill="#FFD700" d="M12 2l2.471 5.499h5.528l-4 4.086 0.942 6.197-4.942-2.721-4.942 2.721 0.942-6.197-4-4.086h5.528z"/>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24">
      <path fill="#FFD700" d="M12 2l2.471 5.499h5.528l-4 4.086 0.942 6.197-4.942-2.721-4.942 2.721 0.942-6.197-4-4.086h5.528z"/>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24">
      <path fill="#FFD700" d="M12 2l2.471 5.499h5.528l-4 4.086 0.942 6.197-4.942-2.721-4.942 2.721 0.942-6.197-4-4.086h5.528z"/>
    </svg>
  </div>
}

export default Star;