import Ads from "../components/Ads";
import GameCategories from "../components/GameCategories";

const PageCategories = () => {
  return <>
    <Ads />
    <GameCategories />
  </> 
}

export default PageCategories;