import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getGame } from '../../apis/index.ts';
import Image from "../Image/index.js";
import Random3Games from "../Random3Games/index.js";
import GamesNew from "../GamesNew/index.js";
import PlaceHolder from "../PlaceHolder/index.js";

const Game = () => {
  const [game, setGame] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    getGame(id).then(data => {
      setLoading(false);
      setGame(data)
    })
  }, []);

  const getLink = (playId) => {
    return `/play/${playId}`;
  }

  if(loading) return <PlaceHolder width="100%" height="100vh" />;

  return (
    <div className="game">
      <div className="grid grid-2 m-grid-1">
        <a href={getLink(game.acf?.play_id || 200)}>
          <Image src={game?._embedded?.['wp:featuredmedia']?.[0]?.source_url} alt={game?.title?.rendered}  />
        </a>
        <div className="flex-v">
          <h1 style={{ marginTop: 0 }}>{game?.title?.rendered}</h1>
          <div className="game-description" dangerouslySetInnerHTML={{ __html: game.acf?.short_description }} />
          <div className="btn-play">
            <a href={getLink(game.acf?.play_id || 200)} className="game-play-now">Play game</a>
          </div>

          <h2 style={{ marginTop: 30 }}>Random Game</h2>
          <Random3Games />
        </div>
      </div>
        <div className="game-content mt-20" dangerouslySetInnerHTML={{ __html: game.content?.rendered }} />
        <h2 className="section-title">New Games</h2>
        <GamesNew hideText={true} noUseId={true} noPaging={true} per_page = {12} />
    </div>
  )
}

export default Game;