
const adsBody = `<!-- /22668877269/2kers.com_2410/2kers.com_PC_banner_2901 -->
<div id='div-gpt-ad-1706502141848-0' style='min-width: 468px; min-height: 60px;'>
  <script>
    googletag.cmd.push(function() { googletag.display('div-gpt-ad-1706502141848-0'); });
  </script>
</div>`;
const Ads = () => {
  return <div className="flex-h" dangerouslySetInnerHTML={{ __html: adsBody }} />
}

export default Ads;