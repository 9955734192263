import React, { useEffect, useState } from "react";
import { getGamesNew } from '../../apis/index.ts';
import Pagination from '../Pagination';
import Card from '../Card';
import Image from '../Image';
import PlaceHolder from "../PlaceHolder/index.js";

const GamesNew = ({categoryId = 0, isMobile = false, hideText, noPaging, maxItem = 48, per_page = 12, layout = { desktop: 6, mobile: 3} }) => {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  useEffect(() => {
    getGamesNew().then(data => {
      if(Array.isArray(data)) {
        if(categoryId) {
          data = data.filter(game => game?.categories?.includes(Number(categoryId)))
        }
        setGames(data)
      }
      setLoading(false);
    })
  }, []);

  const getShow = (page) => {
    if(noPaging) return games.slice(0, maxItem);

    return games.slice((page - 1) * per_page, (page - 1) * per_page + per_page);
  }

  const arr = new Array( noPaging ? maxItem : per_page).fill(null);
  if(loading) return <div className={`games grid grid-${layout.desktop} m-grid-${layout.mobile}`}>
    {arr.map((_, i) => {
      return <PlaceHolder key={`placeholder_games_${i}`} width='100%' height={isMobile ? '110px' : '141.25px'} />
    })}
  </div>;

  return (
    <>
      <div className={`games grid grid-${layout.desktop} m-grid-${layout.mobile}`}>
          {getShow(page).map((game) => {
            return <Card style={ hideText ? { border: 'none' } : {}} key={`new_game_${game.id}`}>
              <a className="a-block" href={`/game/${game.id}`}>
                <Image
                  alt={game.title}
                  src={game.featured_image}
                  />
                {!hideText && <span className="text-primary max-1-line" dangerouslySetInnerHTML={{ __html: game.title }} />}
              </a>
            </Card>
          })}
      </div>
      {!noPaging && games.length > per_page && <div className="flex-h flex-center" style={{ marginTop: 30 }} justify="center">
        <Pagination onPageChange={(page) => setPage(page)} totalItems={games.length} itemsPerPage={per_page} />
      </div>}
    </>
  )
}

export default GamesNew;