import Game from "../components/Game";
import Ads from "../components/Ads";

const PageGames = () => {
  return <>
    <Ads />
    <Game />
  </> 
}

export default PageGames;