import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPlay } from '../../apis/index.ts';
import Image from "../Image/index.js";
import Random3Games from "../Random3Games/index.js";
import GamesNew from "../GamesNew/index.js";
import PlaceHolder from "../PlaceHolder/index.js";
import PopularGames from "../PopularGames/index.js";

const Play = () => {
  const [play, setPlay] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const isDesktop = window.innerWidth > 992 ? true : false;
  const isMobile = window.innerWidth < 767 ? true : false;

  useEffect(() => {
    getPlay(id).then(data => {
      setPlay(data)
      setLoading(false);
    })
  }, []);

  if(loading) return <PlaceHolder width="100%" height="100vh" />;

  return (
    <div className="play">
      <div className="flex-h">
        <div className="responsive-iframe responsive-iframe-mobile" dangerouslySetInnerHTML={{ __html: play?.acf?.iframe_play }} />
        {isDesktop && <Random3Games layout="vertical" per_page={4} /> }
      </div>
      <h2 className="section-title">Popular Games</h2>
      <PopularGames isMobile={isMobile} hideText={true} noPaging={isMobile ? false: false} maxItem={8} per_page={isMobile ? 6: 8} />
      <div className="flex-v">
          <h1  dangerouslySetInnerHTML={{ __html: play?.title?.rendered }}/>
          <div className="flex-h">
            <Image style={{ width: 120, borderRadius: 20, marginRight: 20 }} src={play?._embedded?.['wp:featuredmedia']?.[0]?.source_url} alt={play?.title?.rendered}  />
            <div className="play-short-description fontBold" dangerouslySetInnerHTML={{ __html: play?.excerpt?.rendered }} />
          </div>
      </div>
      <div className="flex-v">
        <h2 className="section-title">New Games</h2>
        <GamesNew hideText={true} noPaging={true} per_page = {12} />
      </div>
    </div>
  )
}

export default Play;